/* Customizations */

$white: #ffffff;

$theme-colors: (
    "primary": #4ec600,
    "secondary": #ffffff,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

html,
body,
#root {
    height: 100%;
}

h3 {
    color: #4ec600;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 1em;
}

.modal-title{
    color: #4ec600 !important;
}


.img-fluid{
    max-width:300px;
}

.container, .container-sm {
    max-width:500px !important;
}

.navbar {
    max-width:500px;
    margin:0 auto;
}




dl dt,
dl dd {
    display: inline;
}

dt {
    color: #4ec600;
    font-weight:bold;
}

dt:before {
    content: "";
    display: block;
    margin-bottom: 0.5em;
}

.cedula-input {
    color: white;
    border: 1px solid white;
    padding: 0.5em;
    border-radius: 5px;
}

.cedula-input::placeholder {
    color: white;
    opacity: 60%;
}

.btn-secondary {
    color: #4ec600 !important;
    font-weight: bold !important;
}

.btn-primary {
    $mynewcolor: #e6086e;
    @include button-variant($mynewcolor, darken($mynewcolor, 7.5%), darken($mynewcolor, 10%), lighten($mynewcolor,5%), lighten($mynewcolor, 10%), darken($mynewcolor,30%));
    font-weight: bold !important;
}

label {
    color: #4ec600 !important;
}

.form-check-label {
    color: #212529 !important;
}
